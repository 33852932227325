<template>
    <div>
        <div class="contents">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="breadcrumb-main user-member justify-content-sm-between ">
                            <div class=" d-flex flex-wrap justify-content-center breadcrumb-main__wrapper">
                                <div
                                    class="d-flex align-items-center user-member__title justify-content-center mr-sm-25">
                                    <h4 class="text-capitalize fw-500 breadcrumb-title">Google Form Script Generator</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-4">
                        <div class="userDatatable global-shadow border p-30 bg-white radius-xl w-100 mb-30">
                            <form v-on:submit.prevent="saveAutoreply">
                                <label> Device </label>
                                <select v-model="form.device" class="form-control" @change="deviceSelect()" required>
                                    <option value="" selected disabled>== PILIH DEVICE ==</option>
                                    <option v-for="d in device" :key="d.id" :value="d.device_key">{{ d.name }}</option>
                                </select>
                                <hr>
                                <label>Nama Kolom (Harus urut)</label>
                                <br>
                                <button type="button" class="btn btn-sm btn-success margin-5" @click="addField">Tambah Field</button>
                                <button type="button" class="btn btn-sm btn-danger margin-5"  @click="deleteField">Hapus Field</button>
                                <br>
                                <div v-for="(f, index) in field" v-bind:key="index">
                                    <br>
                                    <label for="">Field {{ index + 1 }}</label>
                                    <input type="text" v-model="f.value" class="form-control">
                                </div>
                                <hr>
                                <label>List Pesan</label>
                                <br>
                                <button type="button"  class="btn btn-sm btn-success margin-5" @click="addMessage">Tambah Pesan</button>
                                <button type="button"  class="btn btn-sm btn-danger margin-5" @click="deleteMessage">Hapus Pesan</button>
                                <br>
                                <div v-for="(p, idx) in pesan" v-bind:key="'pesan'+idx">
                                    <br>
                                    <label for="">Tujuan</label>
                                    <input type="text" v-model="tujuan[p.index].value" class="form-control">
                                    <br>
                                    <label for="">Pesan</label>
                                    <CustomTextArea v-model="pesan[p.index].value"></CustomTextArea>
                                </div>
                                <br>
                                <i>* Variabel tersedia : [changed] = untuk mengetahui nama kolom yang berubah , [old_value] = untuk mengetahui value sebelum diedit , [value] = untuk mengetahui value setelah diedit</i>
                                <br>
                                <i>* Pakai [] untuk memanggil isi field; contoh [Nomor Whatsapp]</i>
                            </form>
                        </div>
                    </div>
                    <div class="col-lg-8">
                        <div class="userDatatable global-shadow border p-30 bg-white radius-xl w-100 mb-30">
                            <h3 style="text-align: center;">Google Script</h3>
                            <br>
                            <highlightjs javascript :code="finalCode"/>
                            <button v-on:click="copyCode" class="btn btn-success btn-block">Copy</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import CustomTextArea from "@/components/module/CustomTextarea.vue";

    export default {
        components: {
            CustomTextArea,
        },
        data() {
            return {
                device: [],
                form: {
                    device: "",
                },
                apikey: "",
                variable: "",
                tujuanFinal: "",
                pesanFinal: "",
                field: [],
                tujuan: [],
                pesan: [],
                randomStringStorage: [],
                finalCode: ""
            };
        },
        watch: {
            apikey: {
                handler(){
                    this.setFinalCode()
                },
                deep: true
            },
            field:{
                handler(val){
                    this.variable = ""
                    for(let i = 0;i < val.length;i++){
                        if(!val[i].value) continue
                        if(i > 0){
                            this.variable += `
    `
                        }

                        if(!this.randomStringStorage[i]) this.randomStringStorage[i] = this.randomString(5)
                        this.variable += "var " + this.randomStringStorage[i] + ` = rows[row - 1][` + i + `];`
                    }

                    this.setFinalCode()
                },
                deep: true 
            },
            tujuan:{
                handler(val){
                    this.tujuanFinal = ""
                    let dummy = []

                    for(let i = 0;i < val.length;i++){
                        if(!val[i].value) continue
                        if(val[i].value.startsWith("[") && val[i].value.endsWith("]") && this.searchVariable(val[i].value)){
                            dummy.push(this.searchVariable(val[i].value))
                        }else{
                            if(this.ValidateNumber(val[i].value)){
                                dummy.push(`"`+ val[i].value +`"`)
                            }
                        }
                    }

                    let inc = 0
                    for(let i = 0;i < dummy.length;i++){
                        if(dummy[i]){
                            if(inc > 0){
                                this.tujuanFinal += ', '
                            }
                            this.tujuanFinal += dummy[i]
                            inc++
                        }
                    }

                    this.setFinalCode()
                },
                deep: true 
            },
            pesan:{
                handler(val){
                    this.pesanFinal = ""
                    let dummy = []

                    for(let i = 0;i < val.length;i++){
                        if(!val[i].value) continue
                        dummy.push("`" + this.findVariableInMessage(val[i].value) + "`")
                    }

                    let inc = 0
                    for(let i = 0;i < dummy.length;i++){
                        if(dummy[i]){
                            if(inc > 0){
                                this.pesanFinal += ', '
                            }
                            this.pesanFinal += dummy[i]
                            inc++
                        }
                    }

                    this.setFinalCode()
                },
                deep: true 
            }
        },
        mounted() {
            this.getDevice({ status: "all" })
            
            this.setFinalCode()
            //this.variable = `test: event.namedValues["Nomor"][0];,\n        test: event.namedValues["Nomor"][0],`
        },
        methods: {
            async getDevice(param) {
                let res = await this.$store.dispatch("device_list_all", param);
                this.device = res.data.data.devices
            },
            deviceSelect(){
                this.apikey = this.form.device
            },
            addField(){
                this.field.push({
                    index: this.field.length,
                    value: ""
                })
            },
            deleteField(){
                this.field.splice(-1)
            },
            addMessage(){
                this.tujuan.push({
                    index: this.tujuan.length,
                    value: ""
                })
                this.pesan.push({
                    index: this.pesan.length,
                    value: ""
                })
            },
            deleteMessage(){
                this.tujuan.splice(-1)
                this.pesan.splice(-1)
            },
            searchVariable(searchVal){
                for(let i = 0;i < this.field.length;i++){
                    searchVal = searchVal.replaceAll("[", "")
                    searchVal = searchVal.replaceAll("]", "")
                    if(searchVal== this.field[i].value){
                        return this.randomStringStorage[i]
                    }
                }
                return ""
            },
            findVariableInMessage(msg){
                for(let i = 0;i < this.field.length;i++){
                    let search = "[" + this.field[i].value + "]"
                    msg = msg.replaceAll(search, "` + " + this.randomStringStorage[i] + " + `")
                    msg = msg.replaceAll("[changed]", "` + changed + `")
                    msg = msg.replaceAll("[old_value]", "` + old_value + `")
                    msg = msg.replaceAll("[value]", "` + value + `")
                }
                return msg
            },
            copyCode(){
                navigator.clipboard.writeText(this.finalCode)
                this.$toast.success("Kode berhasil di copy")
            },
            setFinalCode(){
                this.finalCode = `function whacenter(event){
    var rows = SpreadsheetApp.getActiveSheet().getDataRange().getValues();
    var row = event['range']['rowStart'];
    var col = event['range']['columnStart'];
    var changed = rows[0][col - 1];
    var old_value = event['oldValue']
    var value = event['value']

    ` + this.variable + `

    var APIKey = "` + this.apikey + `";
    var url = "https://api.whacenter.online/api/send";

    var tujuan = [` + this.tujuanFinal + `];
    var pesan = [` + this.pesanFinal + `];

    for (var i = 0; i < tujuan.length; i++) {
        if(!pesan[i] || !tujuan[i]) continue;

        var payload = {
            "messageType": "text",
            "body": pesan[i],
            "to": tujuan[i]
        };

        var response = UrlFetchApp.fetch(url, {
            "method": "post",
            "headers": {
                "Authorization": APIKey
            },
            "payload": JSON.stringify(payload)
        });
        
        Logger.log(response.getContentText());
    }
}
            `
            },
            randomString(length) {
                let result           = '';
                let characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
                let charactersLength = characters.length;
                for ( let i = 0; i < length; i++ ) {
                    result += characters.charAt(Math.floor(Math.random() * charactersLength));
                }

                return result;
            },
            ValidateNumber(strNumber) {
                var regExp = new RegExp("^\\d+$");
                var isValid = regExp.test(strNumber); // or just: /^\d+$/.test(strNumber);
                return isValid;
            }
        },
    };
</script>

<style scoped>

    .thick-border{
        border: 2px solid #6c6c6c !important;
    }

    .margin-5{
        margin: 5px;
    }

    hr{
        margin-top: 15px;
        margin-bottom: 15px;
    }
</style>